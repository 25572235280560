import { Box } from "@mui/material";
import "./Home.css";
import BallVideo1 from "../../assets/videos/flame-ball.mp4";
import BallVideo2 from "../../assets/videos/yellow-ball.mp4";
import BallVideo3 from "../../assets/videos/blue-ball.mp4";
import BallVideo4 from "../../assets/videos/shimmering-ball.mp4";
import BallVideo5 from "../../assets/videos/boxed-ball.mp4";
import BallVideo6 from "../../assets/videos/string-ball.mp4";
import Logo from "../LogoPage/Logo";

export default function Home() {
  const ballVideos = [
    BallVideo1,
    BallVideo2,
    BallVideo3,
    BallVideo4,
    BallVideo5,
    BallVideo6
  ];

  const randomIndex = Math.floor(Math.random() * ballVideos.length);

  const randomVideo = ballVideos[randomIndex];

  return (
    <Box className="fullHeight" sx={{ overflow: "hidden" }}>
      <Box sx={{ background: "#0c1119c9", width: "100%", height: "100%" }}/>
      <Box
        className="Logo"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 50,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxHeight: "calc(100vh - 56px - 70px)"
        }}
      >
        <Logo />
      </Box>
      <video loop autoPlay muted className="video-background">
        <source src={randomVideo} type="video/mp4" />
      </video>
    </Box>
  );
}
