import { Button, Card, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "../ContactForm/ContactForm.css";

interface IntroductionCardProps {
  introduction: string;
  name: string;
}

export default function IntroductionCard({
  introduction,
  name,
}: IntroductionCardProps) {
  return (
    <Card>
      <CardContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant="h4">INTRODUCTION</Typography>
        <Typography component="p" sx={{ mt: "5rem", textAlign: "center" }}>
          {introduction}
        </Typography>
        <Link
          to={`/resumes/resume_${name}.pdf`}
          target="_blank"
          download={`Resume ${name.charAt(0).toUpperCase() + name.substring(1)} - 2023`}
        >
          <Button
            variant="outlined"
            sx={{
              color: "var(--accent)",
              border: "1px solid var(--accent)",
              mt: "3rem",
              width: "fit-content",
            }}
          >
            Download Resume
          </Button>
        </Link>
      </CardContent>
    </Card>
  );
}
