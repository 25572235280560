import { Box, LinearProgress, Paper } from "@mui/material";
import dataYannick from "../../data/dataYannick";
import dataToon from "../../data/dataToon";
import dataArne from "../../data/dataArne";
import { PersonalData } from "../../types/PersonalData";
import CardBg from "../../assets/images/quiz/whoiswho_card_background.webp";
import WhoIsWhoQuestion from "./Question/WhoIsWhoQuestion";
import { useEffect, useState } from "react";
import Fabs from "./Fabs/Fabs";
import Score from "./Score/Score";

import "./WhoIsWho.css"

export type dataType = {
  arne: PersonalData | null;
  toon: PersonalData | null;
  yannick: PersonalData | null;
};

export type Question = {
  question: string;
  answer: string;
  basedOn: string | object;
  attribute: string;
};

export type Choice = {
  question: string;
  answer: string;
};

export default function WhoIsWho() {
  const data: dataType = {
    arne: dataArne,
    toon: dataToon,
    yannick: dataYannick,
  };
  const filteredData = filterObjectKeys(data, [
    "firstname",
    "lastname",
    "currentJobTitle",
    "skills",
    "portfolioExamples",
    "experiences",
  ]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [reset, setReset] = useState(true);
  const [choices, setChoices] = useState<Choice[]>([
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
  ]);

  function updateChoices(
    currentIndex: number,
    question: string,
    answer: string
  ) {
    const updatedChoices = choices.map((choice, index) => {
      if (choice.question === question) {
        return { ...choice, answer: answer };
      } else if (index === currentIndex) {
        return { question: question, answer: answer };
      } else {
        return choice;
      }
    });

    setChoices(updatedChoices);
  }

  function filterObjectKeys(data: dataType, keyNames: Array<String>) {
    const filteredObject: dataType = {
      arne: null,
      toon: null,
      yannick: null,
    };
    for (const [key, value] of Object.entries(data)) {
      // @ts-ignore
      filteredObject[key] = {};
      for (const keyName of keyNames) {
        // @ts-ignore
        if (value != null && value.hasOwnProperty(keyName)) {
          // @ts-ignore
          filteredObject[key][keyName] = value[keyName];
        }
      }
    }
    return filteredObject;
  }

  function chooseRandomValue(obj: string | Array<any>) {
    if (typeof obj === "string") {
      return obj;
    } else if (typeof obj === "object") {
      const temp = obj[Math.floor(Math.random() * obj.length)];
      if (temp.title) return temp.title;
      if (temp.text) return temp.text;
    }
  }

  function createQuestionText(key: string, text: string) {
    let result =
      "While looking " +
      (key === "currentJobTitle" ? "at" : "through") +
      " their ";

    switch (key) {
      case "skills":
        result += key;
        break;
      case "experiences":
        result += key;
        break;
      case "portfolioExamples":
        result += "portfolio examples";
        break;
      case "currentJobTitle":
        result += "job title";
        break;
      default:
        result += key;
    }

    result += " I found '" + text + "'";

    return result;
  }

  function nextQuestion() {
    if (!choices[currentQuestionIndex].question) {
      updateChoices(
        currentQuestionIndex,
        questions[currentQuestionIndex].question,
        ""
      );
    }
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  }

  function previousQuestion() {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  }

  function handleReset() {
    setCurrentQuestionIndex(0);
    setChoices([
      {
        question: "",
        answer: "",
      },
      {
        question: "",
        answer: "",
      },
      {
        question: "",
        answer: "",
      },
      {
        question: "",
        answer: "",
      },
      {
        question: "",
        answer: "",
      },
      {
        question: "",
        answer: "",
      },
      {
        question: "",
        answer: "",
      },
      {
        question: "",
        answer: "",
      },
      {
        question: "",
        answer: "",
      },
      {
        question: "",
        answer: "",
      },
      {
        question: "",
        answer: "",
      },
      {
        question: "",
        answer: "",
      },
    ]);
    setReset(true);
  }

  function checkChoices() {
    let score = 0;

    for (let index = 0; index < choices.length; index++) {
      const question = questions.filter((question) => question.question === choices[index].question)[0];
      if (question && question.answer === choices[index].answer) {
        score += 1;
      }
    }

    return score;
  }

  useEffect(() => {
    function createQuestions() {
      let temp: Question[] = [];
      Object.keys(filteredData.arne as Object).forEach((k) => {
        if (k !== "firstname" && k !== "lastname") {
          temp.push({
            question: createQuestionText(
              k,
              chooseRandomValue(
                filteredData.arne![k as keyof typeof filteredData.arne]
              )
            ),
            answer: "Arne",
            basedOn: filteredData.arne![k as keyof typeof filteredData.arne],
            attribute: k,
          });
        }
      });
      Object.keys(filteredData.toon as Object).forEach((k) => {
        if (k !== "firstname" && k !== "lastname") {
          temp.push({
            question: createQuestionText(
              k,
              chooseRandomValue(
                filteredData.toon![k as keyof typeof filteredData.toon]
              )
            ),
            answer: "Toon",
            basedOn: filteredData.toon![k as keyof typeof filteredData.toon],
            attribute: k,
          });
        }
      });
      Object.keys(filteredData.yannick as Object).forEach((k) => {
        if (k !== "firstname" && k !== "lastname") {
          temp.push({
            question: createQuestionText(
              k,
              chooseRandomValue(
                filteredData.yannick![k as keyof typeof filteredData.yannick]
              )
            ),
            answer: "Yannick",
            basedOn:
              filteredData.yannick![k as keyof typeof filteredData.yannick],
            attribute: k,
          });
        }
      });

      temp.sort(function (a, b) {
        return Math.random() - 0.5;
      });

      setQuestions(temp);
    }
    
    if (reset) {
      createQuestions();
      setReset(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    function handleUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      event.returnValue = "\\o/";
    };

    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, []);

  return (
    <Box
      className="minFullHeight"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        elevation={24}
        id="question-paper"
        sx={{
          width: "75%",
          minWidth: "20vw",
          maxWidth: "1200px",
          backgroundImage: `url(${CardBg})`,
          backgroundColor: "var(--secondary)",
          backgroundRepeat: "repeat",
          backgroundSize: "350px 350px",
          backgroundBlendMode: "multiply",
          position: "relative",
          mt: 1,
          mb: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        }}
      >
        <LinearProgress
          variant="determinate"
          value={(currentQuestionIndex / questions.length) * 100}
          sx={{
            height: "2%",
            minHeight: "10px",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "var(--accent) !important",
            },
          }}
        />
        {choices && (
          <WhoIsWhoQuestion
            question={questions[currentQuestionIndex]}
            currentQuestionIndex={currentQuestionIndex}
            maxQuestions={questions.length}
            choices={choices}
            updateChoices={updateChoices}
          />
        )}

        {questions[currentQuestionIndex] && (
          <Fabs
            currentQuestionIndex={currentQuestionIndex}
            maxQuestions={questions.length}
            nextQuestion={nextQuestion}
            previousQuestion={previousQuestion}
          />
        )}
        {currentQuestionIndex === questions.length && (
          <Score score={checkChoices()} maxScore={questions.length} handleReset={handleReset} />
        )}
      </Paper>
    </Box>
  );
}
