import { Box } from "@mui/material";
import { Skill } from "../../types/Skill"
import SkillItem from "./SkillItem"

interface SkillsProps {
    skills : Skill[]
}

function Skills({ skills }: SkillsProps) {
  return (
    <Box className="personal-skills" sx={{ width: "100%", mt: "4rem" }}>
      {skills.map((skill) => (
        <SkillItem key={skill.text} skill={skill} />
      ))}
    </Box>
  );
}

export default Skills;
