import Masonry from "@mui/lab/Masonry";
import { Box, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from 'react';

import "./Museum.css";

type MasonryImage = {
  title: string;
  img: string;
  loaded: boolean;
};

export default function Museum() {
  const amountOfImagesManual = 39;
  const [shuffledLayout, setShuffledLayout] = useState<Boolean>(false);
  const [loadedImagesCount, setLoadedImagesCount] = useState<number>(0);
  const [museumImages, setMuseumImages] = useState<MasonryImage[]>(
    Array.from(Array(amountOfImagesManual).keys()).map((index) => {
      return {
        img: `museum-art-${index + 1}.webp`,
        title: `Museum Art ${index + 1}`,
        loaded: false,
      };
    })
  );

  useEffect(() => {
    if (!shuffledLayout) {
      setMuseumImages(currentImages => shuffle([...currentImages]));
      setShuffledLayout(true);
    }
  }, [shuffledLayout]);

  function shuffle(array: MasonryImage[]): MasonryImage[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const handleImageLoad = (imageIndex: number): void => {
    setMuseumImages(currentImages => {
      const newImages = [...currentImages];
      newImages[imageIndex].loaded = true;
      return newImages;
    });
    setLoadedImagesCount(prevCount => prevCount + 1);
  };

  const loadPercentage = (loadedImagesCount / amountOfImagesManual) * 100;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "2550px",
        minHeight: "100vh",
        p: 4,
        marginInline: "auto",
      }}
    >
      <Box className="museum-progress">
        <LinearProgress variant="buffer" value={loadPercentage} valueBuffer={Math.min(loadPercentage + 10, 100)} />
      </Box>

      <Masonry columns={{ sm: 3, md: 4, lg: 5 }} spacing={1.7}>
        {museumImages.map((item, index) => (
          <Box key={index}>
            <img
              src={require(`../../assets/images/museum/${item.img}`)}
              alt={item.title}
              loading="lazy"
              className="masonry-img"
              onLoad={() => handleImageLoad(index)}
            />
          </Box>
        ))}
      </Masonry>
    </Box>
  );
}
