import { Box, Divider, LinearProgress, Typography } from "@mui/material";
import { Choice, Question } from "../WhoIsWho";
import WhoIsWhoButton from "../Button/WhoIsWhoButton";

interface WhoIsWhoQuestionProps {
  question: Question;
  currentQuestionIndex: number;
  maxQuestions: number;
  choices: Choice[];
  updateChoices: (
    currentIndex: number,
    question: string,
    answer: string
  ) => void;
}

export default function WhoIsWhoQuestion({
  question,
  currentQuestionIndex,
  maxQuestions,
  choices,
  updateChoices,
}: WhoIsWhoQuestionProps) {
  return (
    <>
      {!question && currentQuestionIndex !== maxQuestions && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            left: 0,
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            mt: 2.5,
            mb: 2.5,
          }}
        >
          <Typography variant="h4">Loading ...</Typography>
          <LinearProgress sx={{ width: "80%" }} />
        </Box>
      )}
      {question && (
        <Box
          sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 2.5, mb: 2.5 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              maxWidth: "95%"
            }}
          >
            <Typography
              sx={{
                color: "var(--text-title)",
                fontFamily: "questionAnswer",
                fontWeight: "bold",
              }}
            >
              Question {currentQuestionIndex + 1} / {maxQuestions}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "1.2rem",
                maxWidth: "500px",
                color: "var(--text-title)",
                fontFamily: "questionTitle",
              }}
            >
              {question.question}?
            </Typography>
          </Box>
          <Divider sx={{ borderWidth: "1.5px", mt: 1.5, width: "95%" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "90%",
              gap: 2,
              mt: 3.5,
            }}
          >
            <WhoIsWhoButton
              option={"A"}
              text={"Arne"}
              question={question}
              currentQuestionIndex={currentQuestionIndex}
              choices={choices}
              updateChoices={updateChoices}
            />
            <WhoIsWhoButton
              option={"B"}
              text={"Yannick"}
              question={question}
              currentQuestionIndex={currentQuestionIndex}
              choices={choices}
              updateChoices={updateChoices}
            />
            <WhoIsWhoButton
              option={"C"}
              text={"Toon"}
              question={question}
              currentQuestionIndex={currentQuestionIndex}
              choices={choices}
              updateChoices={updateChoices}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
