import backgroundArne from "../assets/images/portfolio/section1-background-arne.webp"
import thecodezoneImage from "../assets/images/portfolio-examples/arne/thecodezone.webp"
import syslogImage from "../assets/images/portfolio-examples/arne/syslogserver.webp"
import simulatorImage from "../assets/images/portfolio-examples/arne/callSimulator.webp"
import helsenImage from "../assets/images/portfolio-examples/arne/AppartementsHelsen.webp"
import doulaImage from "../assets/images/portfolio-examples/arne/DoulaLinde.webp"
import pictureSection2 from "../assets/images/portfolio/section2-background-arne.webp"
import pictureSection3 from "../assets/images/portfolio/section3-background-arne.webp"
import pictureSection4 from "../assets/images/portfolio/section4-background-arne.webp"

let data = {
  firstname: "Arne",
  lastname: "Helsen",
  currentJobTitle: "System Engineer",
  pictureSection1: backgroundArne,
  pictureSection2: pictureSection2,
  pictureSection3: pictureSection3,
  pictureSection4: pictureSection4,
  introduction: "I'm Arne, a System Engineer at Eurofunk Kappacher GmbH. Since a young age, I've been fascinated by computers, which fueled my passion for learning about new technologies. Outside of work, my interests span playing the guitar, gaming, and skiing.",
  skills: [
    {
      text: "Bash",
      value: 95,
    },
    {
      text: "Ansible",
      value: 95,
    },
    {
      text: "AWS",
      value: 80,
    },
    {
      text: "Python",
      value: 85,
    },
    {
      text: "Powershell",
      value: 70,
    },
    {
      text: "Containerization",
      value: 80,
    },
    {
      text: "Virtualization",
      value: 90,
    },
    {
      text: "Network Administration",
      value: 90,
    },
    {
      text: "Linux Administration",
      value: 95,
    },
    {
      text: "Telecommunication",
      value: 85,
    },
    {
      text: "HTML / CSS",
      value: 75,
    },
    {
      text: "Wordpress",
      value: 80,
    }
  ],
  portfolioExamples: [
    {
      img: syslogImage,
      title: "Syslogserver"
    },
    {
      img: simulatorImage,
      title: "Emergency Call Simulator"
    },
    {
      img: thecodezoneImage,
      title: "TheCodeZone"
    },
    {
      img: helsenImage,
      title: "Appartements Helsen"
    },
    {
      img: doulaImage,
      title: "Doula Linde"
    }
  ],
  experiences: [
    {
      id: 1,
      title: "System Engineer",
      location: "St. Johann im Pongau, Salzburg (AT)",
      description: "Currently, I'm a Systems Engineer at Eurofunk, specializing in developing deployment software for mission-critical communication platforms, especially those used in dispatching police and fire brigade operations. In addition to this, I handle various DevOps responsibilities and oversee the robust management of our server infrastructure.",
      buttonText: "Eurofunk Website",
      buttonLink: "https://www.eurofunk.com/?lang=en",
      date: "April 2023 - present",
      icon: "work"
    },
    {
      id: 2,
      title: "Communication Solution Engineer",
      location: "St. Johann im Pongau, Salzburg (AT)",
      description: "As a Communication Solutions Engineer at Eurofunk, I focussed on deploying and configuring communication software for critical infrastructures such as dispatching applications for police and fire brigades.",
      buttonText: "Eurofunk Website",
      buttonLink: "https://www.eurofunk.com/?lang=en",
      date: "June 2021 - April 2023",
      icon: "work"
    },
    {
      id: 3,
      title: "Information Technology Trainee",
      location: "St. Johann im Pongau, Salzburg (AT)",
      description: "During this 1 year traineeship at Eurofunk, I got to train in many different departments and gain experience in Networking, Telecommunications, Multimedia infrastructure, Virtualization, and Deployment technologies such as Ansible.",
      buttonText: "Eurofunk Website",
      buttonLink: "https://www.eurofunk.com/?lang=en",
      date: "June 2020 - June 2021",
      icon: "work"
    },
    {
      id: 4,
      title: "Ski Shop Assistant/Technician",
      location: "Mühlbach am Hochkönig, Salzburg (AT)",
      description: "At Sport Klaus, I focussed on Ski and Snowboard rental as well as Ski and Snowboard repairs.",
      buttonText: "Sport Klaus Website",
      buttonLink: "https://www.sportklaus.at/",
      date: " December 2019 - April 2020",
      icon: "work"
    },
    {
      id: 5,
      title: "Cook and Waiter",
      location: "Maria Alm, Salzburg (AT)",
      description: "After several summer internships at Hotel Sonnenlicht, I returned to work as a cook and waiter for another season.",
      buttonText: "Hotel Sonnenlicht Website",
      buttonLink: "https://www.hotel-sonnenlicht.at/en/",
      date: " August 2019 - September 2019",
      icon: "work"
    },
    {
      id: 6,
      title: "General qualification for university entrance",
      location: "St. Johann im Pongau, Salzburg (AT)",
      description: "I got my general qualification for university entrance (Matura) at WIFI St. Johann. Focus: Information management and media technology.",
      buttonText: "WIFI Website",
      buttonLink: "https://www.wifisalzburg.at/",
      date: "September 2018 - June 2019",
      icon: "school"
    },
    {
      id: 7,
      title: "Freelance Translator",
      location: "Global",
      description: "While still in school, I did freelance translations and transcriptions for UpWork to earn extra money. Mainly translations in following directions: English ↔ Dutch, German → English.",
      buttonText: "UpWork Website",
      buttonLink: "https://www.upwork.com/",
      date: "Throughout 2019",
      icon: "work"
    },
    {
      id: 8,
      title: "Ski Instructor",
      location: "Eben im Pongau, Salzburg (AT)",
      description: "During school vacations and weekends, I worked as a ski instructor at the ski school in Eben im Pongau.",
      buttonText: "Skischule Eben Website",
      buttonLink: "https://skischule-eben.at/",
      date: "February 2019 - March 2019",
      icon: "work"
    },
    {
      id: 9,
      title: "Cook and Waiter",
      location: "Maria Alm, Salzburg (AT)",
      description: "After graduating from the tourism school and completing successful internships at Hotel Sonnenlicht, I was hired as a cook and waiter.",
      buttonText: "Hotel Sonnenlicht Website",
      buttonLink: "https://www.hotel-sonnenlicht.at/en/",
      date: "July 2018 - August 2018",
      icon: "work"
    },
    {
      id: 10,
      title: "Hotel/Restaurant Industry and Nutrition",
      location: "Bischofshofen, Salzburg (AT)",
      description: "After moving to Austria I started and graduated from the Vocational School for the Hotel and Restaurant Industry in Bischofshofen. Training focus: Restaurant Industry and Nutrition, Tourismusschulen Salzburg Bischofshofen.",
      buttonText: "TS-Salzburg Website",
      buttonLink: "https://ts-salzburg.at/",
      date: "September 2015 - June 2018",
      icon: "school"
    },
    {
      id: 11,
      title: "Intern",
      location: "Maria Alm, Salzburg (AT)",
      description: "My second internship as a cook and waiter at Hotel Sonnenlicht.",
      buttonText: "Hotel Sonnenlicht Website",
      buttonLink: "https://www.hotel-sonnenlicht.at/en/",
      date: "June 2017 - September 2017",
      icon: "work"
    },
    {
      id: 12,
      title: "Intern",
      location: "Mühlbach am Hochkönig, Salzburg (AT)",
      description: "Internship as a waiter at Appartements Helsen.",
      buttonText: "Appartements Helsen Website",
      buttonLink: "https://appartements-helsen.com/",
      date: "December 2016",
      icon: "work"
    },
    {
      id: 13,
      title: "Intern",
      location: "Maria Alm, Salzburg (AT)",
      description: "My first internship as a cook and waiter at Hotel Sonnenlicht.",
      buttonText: "Hotel Sonnenlicht Website",
      buttonLink: "https://www.hotel-sonnenlicht.at/en/",
      date: "June 2016 - September 2016",
      icon: "work"
    },
    {
      id: 14,
      title: "Intern",
      location: "Mühlbach am Hochkönig, Salzburg (AT)",
      description: "Internship as a waiter at Marmotta Alpin Hotel.",
      buttonText: "Marmotta Alpin Hotel Website",
      buttonLink: "https://www.marmotta-alpinhotel.com/",
      date: "December 2015",
      icon: "work"
    },
    {
      id: 15,
      title: "I moved to Austria",
      location: "Mühlbach am Hochkönig, Salzburg (AT)",
      description: "At the age of almost 16, I moved to the beautiful Mühlbach am Hochkönig in Austria. My parents decided to start up an apartment house here. A difficult but fun and valuable experience.",
      buttonText: "Appartements Helsen Website",
      buttonLink: "https://appartements-helsen.com/",
      date: "August 2015",
      icon: "moving"
    },
    {
      id: 16,
      title: "Ski Instructor",
      location: "Heist-op-den-Berg, Antwerp (BE)",
      description: "After obtaining my first ski instructor certificate, I traveled to Val di Sole, Italy for my initial experience as a ski instructor.",
      buttonText: "Snowmania Website",
      buttonLink: "https://www.snowmania.be/",
      date: "March 2015",
      icon: "work"
    },
    {
      id: 17,
      title: "Tourism",
      location: "Herentals, Antwerp (BE)",
      description: "I changed secondary school to the kOsh Campus Bovenrij in Herentals. Studying tourism better suited my plans of moving to Austria the next year.",
      buttonText: "kOsh Info",
      buttonLink: "https://kosh.be/studieprofielen/toerisme-derde-jaar/",
      date: "September 2014 - June 2015",
      icon: "school"
    },
    {
      id: 18,
      title: "General Sciences",
      location: "Vorselaar, Antwerp (BE)",
      description: "I went to middle school at the Kardinaal Van Roey Institute in Vorselaar.",
      buttonText: "KVRI Website",
      buttonLink: "https://kvri.be/",
      date: "September 2011 - June 2014",
      icon: "school"
    },
  ],
  contactData: {
    email: "arnehelsen@thecodezone.tech"
  }
}

export default data;