import {
  AppBar,
  Box,
  createTheme,
  IconButton,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Route, BrowserRouter as Router, useHistory } from "react-router-dom";
import "./App.css";
import { useState } from "react";
import { Navigation } from "./components/Navigation/Navigation";
import { Switch } from "react-router-dom";
import Home from "./components/HomePage/Home";
import ArneHomePage from "./components/ArneHomePage/ArneHomePage";
import YannickHomePage from "./components/YannickHomePage/YannickHomePage";
import ToonHomePage from "./components/ToonHomePage/ToonHomePage";
import Footer from "./components/Footer/Footer";
import WhoIsWho from "./components/WhoIsWho/WhoIsWho";
import Museum from "./components/MuseumPage/Museum";
// import WordSearch from "./components/WordSearch/WordSearch";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4d5979",
    },
    secondary: {
      main: "#BEC7D5",
    },
  },
});

type HeaderProps = {
  onOpenDrawer: () => void;
};

const MyAppBar = ({ onOpenDrawer }: HeaderProps) => {
  const history = useHistory();
  return ( 
    <AppBar position="static" sx={{ backgroundColor: `${history.location.pathname === '/' ? 'rgb(8 12 17)' : 'hsla(218, 10%, 50%, 1)' }` }}>
      <Toolbar>
        <IconButton color="inherit" sx={{ mr: 2 }} onClick={onOpenDrawer}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" sx={{ fontFamily: "title", fontSize: "15pt", cursor: "pointer" }} onClick={() => history.push('/')}>
          The Code Zone
        </Typography>
      </Toolbar>
    </AppBar>
  )
};

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Box className="App">
          <MyAppBar onOpenDrawer={handleDrawerToggle} />
          <Navigation
            isOpen={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          />
          <Switch>
            <Route path="/museum">
              <Museum/>
            </Route>
            {/* <Route path="/wordsearch">
              <WordSearch/>
            </Route> */}
            <Route path="/whoiswho">
              <WhoIsWho/>
            </Route>
            <Route path="/arne">
              <ArneHomePage />
            </Route>
            <Route path="/yannick">
              <YannickHomePage />
            </Route>
            <Route path="/toon">
              <ToonHomePage />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <Footer />
        </Box>
      </ThemeProvider>
    </Router>
  );
}

export default App;
