import { Box, Fab, Typography } from "@mui/material";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

import "./Fabs.css";

interface FabsProps {
  maxQuestions: number;
  currentQuestionIndex: number;
  previousQuestion: () => void;
  nextQuestion: () => void;
}

export default function Fabs({
  currentQuestionIndex,
  maxQuestions,
  previousQuestion,
  nextQuestion,
}: FabsProps) {
  return (
    <>
      <Box sx={{ width: "100%", flexGrow: 1 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mx: 2,
          mb: 2
        }}
      >
        <Box id="previous">
          <Fab
            aria-label="previous"
            className="fab"
            disabled={currentQuestionIndex === 0}
            onClick={previousQuestion}
          >
            <SkipPreviousIcon />
          </Fab>
          <Fab
            aria-label="previous"
            className="fab-extended"
            variant="extended"
            disabled={currentQuestionIndex === 0}
            onClick={previousQuestion}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SkipPreviousIcon sx={{ mr: 1 }} />
              <Typography sx={{ fontWeight: "bold" }}>Previous</Typography>
            </Box>
          </Fab>
        </Box>
        <Box id="next">
          <Fab aria-label="next" className="fab" onClick={nextQuestion}>
            <SkipNextIcon />
          </Fab>
          <Fab
            aria-label="next"
            className="fab-extended"
            variant="extended"
            onClick={nextQuestion}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SkipNextIcon sx={{ mr: 1 }} />
              <Typography sx={{ fontWeight: "bold" }}>
                {currentQuestionIndex === maxQuestions - 1 ? "Finish" : "Next"}
              </Typography>
            </Box>
          </Fab>
        </Box>
      </Box>
    </>
  );
}
