import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import "./Footer.css";

function Footer() {
  const history = useHistory();
  return <Box className="footer" sx={{ backgroundColor: `${history.location.pathname === '/' ? 'hsl(215deg 54% 7% / 73%)' : 'var(--primary-dark)' }` }}>© 2023 by TheCodeZone.</Box>;
}

export default Footer;
