import { Box, Slider, Typography } from "@mui/material";
import { Skill } from "../../types/Skill";
import "./SkillItem.css";

interface SkillItemProps {
  skill: Skill;
}

function SkillItem({ skill }: SkillItemProps) {
  return (
    <Box className="skill-item">
      <Typography
        variant="h5"
        className="skill-title"
      >
        {skill.text}
      </Typography>
      <Slider
        defaultValue={skill.value}
        step={5}
        marks
        min={0}
        max={100}
        disabled
        valueLabelDisplay="on"
        sx={{
          '&': {
            color: "var(--accent-dark) !important"
          },
          '& .MuiSlider-rail': {
            color: "var(--accent) !important"
          },
          '& .MuiSlider-track': {
            color: "var(--accent) !important"
          }
        }}
      />
    </Box>
  );
}

export default SkillItem;
