import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import "./PersonalHome.css";
import Skills from "../Skills/Skills";
import { PersonalData } from "../../types/PersonalData";
import Experiences from "../Experiences/Experiences";
import PortfolioExamples from "../Portfolio/PortfolioExamples";
import ContactForm from "../ContactForm/ContactForm";
import IntroductionCard from "../IntroductionCard/IntroductionCard";

interface PersonalHomePageProps {
  data: PersonalData;
}

export default function PersonalHomePage({ data }: PersonalHomePageProps) {
  const history = useHistory();

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Box
        className="personal-section"
        sx={{ background: `url(${data.pictureSection1}), rgb(63, 73, 90)` }}
      >
        <Box className="personal-section-content">
          <Box className="personal-main-title">
            <Typography variant="h1">
              I<Box className="color-accent">'</Box>m
            </Typography>
            <Typography variant="h1">{data.firstname}</Typography>
            <Typography variant="h1">
              {data.lastname}
              <Box className="color-accent">.</Box>
            </Typography>
          </Box>
          <Box className="personal-main-subtitle">
            <Typography variant="h5">{data.currentJobTitle}</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        className="personal-section"
        sx={{ background: `url(${data.pictureSection2}), rgb(66, 72, 82)` }}
      >
        <IntroductionCard
          introduction={data.introduction}
          name={data.firstname.toLowerCase()}
        />
        <Box className="personal-section-content" sx={{ mt: "20rem" }}>
          <Box>
            <Typography variant="h3" className="personal-title-number">
              01
            </Typography>
            <Typography variant="h3" className="personal-title-text">
              Professional
            </Typography>
          </Box>
          <Typography variant="h5" className="personal-subtitle">
            My knowledge level in software
          </Typography>
          <Skills skills={data.skills} />
        </Box>
      </Box>
      <Box
        className="personal-section"
        sx={{ background: `url(${data.pictureSection3}), rgb(45, 51, 68)` }}
      >
        <Box className="personal-section-content">
          <Box>
            <Typography variant="h3" className="personal-title-number">
              02
            </Typography>
            <Typography variant="h3" className="personal-title-text">
              Portfolio
            </Typography>
          </Box>
          <Box className="personal-subtitle">
            <Typography variant="h5">My latest work. </Typography>
            <Typography
              variant="h5"
              component="div"
              className="personal-link"
              onClick={() =>
                history.push(`/${data.firstname.toLowerCase()}/portfolio`)
              }
            >
              See more &gt;
            </Typography>
          </Box>
          <PortfolioExamples portfolioExamples={data.portfolioExamples} />
          <Box className="personal-section-spacer">
            <Typography variant="h3" className="personal-title-number">
              03
            </Typography>
            <Typography variant="h3" className="personal-title-text">
              Experience
            </Typography>
          </Box>
          <Experiences experiences={data.experiences} />
        </Box>
      </Box>
      <Box
        className="personal-section"
        sx={{ position: "relative", background: `url(${data.pictureSection4}), rgb(35, 39, 51)` }}
      >
        <ContactForm contactData={data.contactData} />
      </Box>
    </Box>
  );
}
