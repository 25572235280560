import { ContactFormData } from "../components/ContactForm/ContactForm";
import emailjs from "@emailjs/browser";

export function sendEmail(formData: ContactFormData, receiver: string) {
  const data = {
    subject: "New Message from " + formData.name,
    name: formData.name,
    phone: formData.phone,
    message: formData.message,
    send_by: formData.email,
    send_to: receiver,
  }
  return emailjs
    .send(
      "service_vb2eext",
      "template_18uhzl4",
      data,
      "OEY_rNY7gmV5sgX6Y"
    );
}
