import { Box, ImageList, ImageListItem } from "@mui/material";
import { PortfolioExample } from "../../types/PortfolioExample";
import "./PortfolioExamples.css";

interface PortfolioExamplesProps {
  portfolioExamples: PortfolioExample[];
}

export default function PortfolioExamples({
  portfolioExamples,
}: PortfolioExamplesProps) {
  return (
    <ImageList
      className="portfolio-example-list"
      sx={{
        maxWidth: "90%",
        mt: "5rem",
        gap: "1rem !important",
      }}
    >
      {portfolioExamples.map((example) => (
        <ImageListItem className="portfolio-example-list-item" key={example.img} sx={{ position: "relative" }}>
          <img src={example.img} alt={example.title} loading="lazy" />
          <Box
            className="portfolio-example-list-item-title"
            sx={{
              position: "absolute",
              inset: 0,
              background: "#00000080",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textTransform: "uppercase",
              fontWeight: "600",
              letterSpacing: ".25em",
              textAlign: "center",
              lineHeight: "1.5rem"
            }}
          >
            {example.title}
          </Box>
        </ImageListItem>
      ))}
    </ImageList>
  );
}
