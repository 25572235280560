import { Box, Paper } from "@mui/material";
import { Choice, Question } from "../WhoIsWho";

interface WhoIsWhoButtonProps {
  option: string;
  text: string;
  question: Question;
  currentQuestionIndex: number;
  choices: Choice[];
  updateChoices: (currentIndex: number, question: string, answer: string) => void;
}

export default function WhoIsWhoButton({
  option,
  text,
  question,
  currentQuestionIndex,
  choices,
  updateChoices,
}: WhoIsWhoButtonProps) {
  return (
    <Paper
      variant="outlined"
      sx={{
        height: "5rem",
        width: "20rem",
        maxWidth: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "17px",
        backgroundColor: (
          choices[currentQuestionIndex].answer === text
            ? "var(--primary-darker)"
            : "ghostwhite"
        ),
        boxShadow: "6px 10px 21px -9px rgba(122,122,122,1)",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "var(--primary)",
          transform: "scale(1.025)",
          transition:
            "transform ease var(--tran-dur), background-color ease var(--tran-dur)",
          "& .button-option": {
            backgroundColor: "var(--primary-dark)",
            color: "white",
            transition:
              "color ease var(--tran-dur), background-color ease var(--tran-dur)",
          },
          "& .button-text": {
            color: "white",
            transition: "color ease var(--tran-dur)",
          },
        },
      }}
      onClick={() => {
        updateChoices(currentQuestionIndex, question.question, text);
      }}
    >
      <Box
        className="button-option"
        sx={{
          mr: 2,
          ml: 2.5,
          p: 1,
          height: "2.25rem",
          width: "2.25rem",
          borderRadius: "999px",
          backgroundColor:  (
            choices[currentQuestionIndex].answer === text
              ? "var(--primary-dark)"
              : "var(--secondary)"
          ),
          color: (
            choices[currentQuestionIndex].answer === text
              ? "white"
              : "black"
          ),
          fontFamily: "questionTitle",
          textAlign: "center",
          userSelect: "none",
        }}
      >
        {option}
      </Box>
      <Box
        className="button-text"
        sx={{
          fontFamily: "questionAnswer",
          fontWeight: "bold",
          userSelect: "none",
          color:  (
            choices[currentQuestionIndex].answer === text
              ? "white"
              : "black"
          ),
        }}
      >
        {text}
      </Box>
    </Paper>
  );
}
