import { Box } from "@mui/material";
import { useState, useRef } from "react";

import "./Logo.css";

export default function Logo() {
  const [effectStarted, setEffectStarted] = useState(false);
  const logoText = useRef<any>(null);
  const divider = useRef<any>(null);
  const welcome = useRef<any>(null);

  window.addEventListener("load", function () {
    if (!effectStarted) {
      setTimeout(function () {
        if (divider.current) divider.current.style.transform = "scaleY(100%)";
        setTimeout(function () {
          if (logoText.current)
            logoText.current.style.transform = "translateX(0)";
          setTimeout(function () {
            if (welcome.current)
              welcome.current.style.transform = "translateX(0)";
          }, 1500);
        }, 1000);
      }, 500);
    }
    setEffectStarted(true);
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          userSelect: "none",
        }}
      >
        <Box className="rotate-img" />
        <Box
          ref={divider}
          sx={{
            backgroundColor: "var(--logo-secondary)",
            width: "0.7vw",
            borderRadius: "4px",
            transform: "scaleY(0%)",
            transition: "transform 1.5s ease",
            transformOrigin: "top",
          }}
        />
        <Box sx={{ overflowX: "hidden", pl: "4vw" }}>
          <Box
            ref={logoText}
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "white",
              fontFamily: "logoFont",
              justifyContent: "center",
              transform: "translateX(-120%)",
              transition: "transform 1s ease",
              height: "100%",
            }}
          >
            <Box sx={{ fontSize: "4.5vw", lineHeight: "4vw" }}>THE</Box>
            <Box sx={{ fontSize: "6.5vw", lineHeight: "6vw" }}>CODE</Box>
            <Box sx={{ fontSize: "5.75vw", lineHeight: "5vw" }}>ZONE</Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "40%", mx: "auto", overflowX: "hidden" }}>
        <Box
          ref={welcome}
          className="welcome"
          sx={{
            color: "white",
            fontFamily: "logoFont",
            fontSize: "2vw",
            mt: "40px",
            textAlign: "center",
            transform: "translateX(-100%)",
            transition: "transform 1s ease",
            userSelect: "none",
          }}
        >
          Welcome, Bienvenue, Willkommen, Welkom
        </Box>
      </Box>
    </>
  );
}
