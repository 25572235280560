import backgroundToon from "../assets/images/portfolio/section1-background-toon.webp"
import cubesat from "../assets/images/portfolio-examples/toon/cubesat.webp"
import ocp from "../assets/images/portfolio-examples/toon/ocp17.webp"
import conveyor from "../assets/images/portfolio-examples/toon/convbelt.webp"
import uno from "../assets/images/portfolio-examples/toon/uno.webp"
import pictureSection2 from "../assets/images/portfolio/section2-background-toon.webp"
import pictureSection3 from "../assets/images/portfolio/section3-background-toon.webp"
import pictureSection4 from "../assets/images/portfolio/section4-background-toon.webp"

let data = {
    firstname: "Toon",
    lastname: "Willems",
    currentJobTitle: "Back-end Developer/Data Scientist",
    pictureSection1: backgroundToon,
    pictureSection2: pictureSection2,
    pictureSection3: pictureSection3,
    pictureSection4: pictureSection4,
    introduction: "My name is Toon, an industrial engineer focused on electronics and ICT that lives in Poederlee together with his dog Jimbo. During my studies I found my love for computer science and working with embedded systems. Researching algorithms, testing them in constrained environments and seeing data as a result is what drives me to become an expert in the field of software development and data science. In my free time I mainly free my mind by entertaining my dog or going out for a run.",
    skills: [
        {
          text: "Python",
          value: 95,
        },
        {
          text: "Java",
          value: 85,
        },
        {
          text: "Spring",
          value: 70
        },
        {
          text: "Machine Learning",
          value: 80,
        },
        {
          text: "Deep Learning",
          value: 70,
        },
        {
          text: "Embedded Systems",
          value: 80,
        },
        {
          text: "HTML/CSS",
          value: 75,
        },
        {
          text: "C/C++",
          value: 65,
        },
        {
          text: "C# (unity)",
          value: 65,
        },
        {
          text: "Photoshop",
          value: 70,
        },
    ],
    portfolioExamples: [
      {
        img: conveyor,
        title: "Masterthesis"
      },
      {
        img: cubesat,
        title: "Bachelorthesis"
      },
      {
        img: uno,
        title: "UNO"
      },
	  {
        img: ocp,
        title: "Oracle Certified Professional 17"
      },
    ],
    experiences: [
        {
            id: 1,
            title: "Java Consultant @Axxes",
            location: "Antwerp, Antwerp (BE)",
            description: "I currently work as a Java Consultant for Axxes in Antwerp. As a part of the back-end team I constantly search for possible improvements and new implementations.",
            buttonText: "Axxes IT Website",
            buttonLink: "https://www.axxes.com/en",
            date: "August 2022 - Today",
            icon: "work"
        },
        {
            id: 2,
            title: "Masterthesis student @ADVISE",
            location: "Geel, Antwerp (BE)",
            description: "My goal as a thesis student was to research and build a demo setup for datadriven conditionsurveillance via noise- and accelerationdata.",
            buttonText: "ADVISE website",
            buttonLink: "https://iiw.kuleuven.be/onderzoek/advise",
            date: "October 2021 - August 2022",
            icon: "work"
        },
        {
            id: 3,
            title: "Bachelorthesis student @Aether",
            location: "Geel, Antwerp (BE)",
            description: "My main focus in the bachelorsthesis was to research an EPS for a CubeSat.",
            buttonText: "Aether's Website",
            buttonLink: "https://aetherspace.be/",
            date: "September 2020 - June 2021",
            icon: "work"
        },
        {
            id: 4,
            title: "Delivery driver/stockroom worker",
            location: "Lille, Antwerp (BE)",
            description: "This was a vacation job I used to do when I was in middle school.",
            buttonText: "Wijnen Jacobs's Website",
            buttonLink: "https://www.wijnenjacobs.be/",
            date: "Summers 2015 - 2018",
            icon: "work"
        },
        {
            id: 5,
            title: "Master Industrial Engineering option ICT",
            location: "Geel, Antwerp (BE)",
            description: "I followed up my bachelorsdegree at the KU Leuven campus Geel (Belgium).",
            buttonText: "Masterdegree Info",
            buttonLink: "https://onderwijsaanbod.kuleuven.be/opleidingen/n/CQ_51236262.htm#activetab=diploma_omschrijving",
            date: "September 2020 - June 2023",
            icon: "school"
        },
        {
            id: 6,
            title: "Bachelor Industrial Engineering option Electronics/ICT",
            location: "Geel, Antwerp (BE)",
            description: "My first studies after highschool I chose to study at the KU Leuven campus Geel (Belgium).",
            buttonText: "Bachelorsdegree Info",
            buttonLink: "https://onderwijsaanbod.kuleuven.be/opleidingen/n/CQ_51236207.htm#activetab=diploma_omschrijving",
            date: "September 2017 - August 2020",
            icon: "school"
        },
		{
            id: 7,
            title: "Sciencec mathemathics",
            location: "Vorselaar, Antwerp (BE)",
            description: "In highschool I chose to study at KVRI in Vorselaar.",
            buttonText: "Study Info",
            buttonLink: "https://kvri.be/",
            date: "September 2012 - June 2017",
            icon: "school"
        },
    ],
    contactData: {
      email: "toon.w@hotmail.com"
    }
}

export default data;