import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemButton,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ArneIcon from "@mui/icons-material/Dns";
import YannickIcon from "@mui/icons-material/Computer";
import ToonIcon from "@mui/icons-material/BarChartSharp";
import WhoIsWhoIcon from '@mui/icons-material/PsychologyAlt';
import MuseumIcon from '@mui/icons-material/Museum';
// import WordSearchIcon from '@mui/icons-material/Extension';

type NavigationProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function Navigation({ isOpen, onClose }: NavigationProps) {
  return (
    <Drawer open={isOpen} onClose={onClose}>
      <List sx={{ width: 200 }}>
        {[
          {
            label: "Home",
            href: "/",
            icon: <HomeIcon />,
          },
          {
            label: "divider 1",
          },
          {
            label: "Arne",
            href: "/arne",
            icon: <ArneIcon />,
          },
          {
            label: "Yannick",
            href: "/yannick",
            icon: <YannickIcon />,
          },
          {
            label: "Toon",
            href: "/toon",
            icon: <ToonIcon />,
          },
          {
            label: "divider 2",
          },
          {
            label: "Who is Who",
            href: "/whoiswho",
            icon: <WhoIsWhoIcon />,
          },
          // {
          //   label: "WordSearch Puzzle",
          //   href: "/wordsearch",
          //   icon: <WordSearchIcon />,
          // },
          {
            label: "divider 3",
          },
          {
            label: "Museum",
            href: "/museum",
            icon: <MuseumIcon />,
          },
        ].map((item) =>
          !item.label.includes("divider") ? (
            <ListItem disableGutters key={item.label}>
              <ListItemButton component="a" href={item.href}>
                <ListItemIcon sx={{ ml: "5%" }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ) : (
            <Divider key={item.label} />
          )
        )}
      </List>
    </Drawer>
  );
}
