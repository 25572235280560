import axios from "axios";

export function validateCaptcha(value: string) {
    return axios.post(
        "https://www.google.com/recaptcha/api/siteverify",
        null,
        { 
            params: { 
                secret: "6LdO_vkfAAAAANFx4s-wog90MfYsA-yIqfyk2zSX", 
                response: value 
            }, 
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
            } 
        }
    )
}