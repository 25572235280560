import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { sendEmail } from "../../services/email-service";
import { ContactData } from "../../types/ContactData";
import "./ContactForm.css";
import ReCAPTCHA from "react-google-recaptcha";
import { validateCaptcha } from "../../services/google-recaptcha";

interface ContactFormProps {
  contactData: ContactData;
}

export type ContactFormData = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

export default function ContactForm({ contactData }: ContactFormProps) {
  const [formData, setFormData] = useState<ContactFormData>({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isValid, setIsValid] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [captchaValid, setCaptchaValid] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showMessageError, setShowMessageError] = useState(false);
  const [emailResponseText, setEmailResponseText] = useState('');

  useEffect(() => {
    if (
      formData.name !== "" &&
      formData.email !== "" &&
      formData.message !== "" &&
      /^[a-zA-Z0-9-_]+(?:\.?[a-zA-Z0-9-_]+)@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email) &&
      (/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(formData.phone) || formData.phone === "")
    ) {
      setFormValid(true);
      if (captchaValid) setIsValid(true);
    } else {
      setFormValid(false);
      setIsValid(false);
    }
  }, [captchaValid, formData]);

  function handleChange(property: string, value: string) {
    setFormData({ ...formData, [property]: value });
    switch (property) {
      case "name":
        setShowNameError(value === "");
        break;

      case "email":
        setShowEmailError(value === "");
        break;

      case "message":
        setShowMessageError(value === "");
        break;
    }
    setEmailResponseText("");
  }

  function clearContactForm() {
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
    })
  }

  function onCaptchaChange(value: string | null) {
    console.log("NEW VALUE RECEIVED: ",value);
    if (value === null) {
      setIsValid(false);
      return;
    }
    validateCaptcha(value).then((response) => {
      console.log("CAPTCHA VALIDATED: ",response.data.success);
      setCaptchaValid(response.data.success);
      setIsValid(response.data.success && formValid);
    })
  }

  return (
    <Card>
      <CardContent className="contact-form-content">
        <Box className="contact-form-text">
          <Typography variant="h5">CONTACT</Typography>
          <Typography component="p" sx={{ mt: "2rem" }}>
            If you wish to contact me, you can do so by filling in the form.
          </Typography>
        </Box>
        <Box component="form" className="contact-form-form">
          <Box className="contact-form-combined-inputs" sx={{ display: "flex", gap: "1rem" }}>
            <TextField
              id="name"
              label="Name"
              variant="filled"
              required
              sx={{ width: "100%" }}
              error={showNameError}
              value={formData.name}
              onChange={(event) => handleChange("name", event.target.value)}
            />
            <TextField
              id="email"
              label="Email"
              variant="filled"
              required
              sx={{ width: "100%" }}
              error={showEmailError}
              value={formData.email}
              onChange={(event) => handleChange("email", event.target.value)}
            />
          </Box>
          <TextField
            id="phone"
            label="Phone"
            variant="filled"
            value={formData.phone}
            onChange={(event) => handleChange("phone", event.target.value)}
          />
          <TextField
            id="message"
            label="Message"
            variant="filled"
            required
            error={showMessageError}
            value={formData.message}
            onChange={(event) => handleChange("message", event.target.value)}
          />
          <ReCAPTCHA
            sitekey="6LdO_vkfAAAAANXAMVxj195FMYctutzubLZlG6qg"
            onChange={onCaptchaChange}
            theme="dark"
          />
          <Button
            variant="contained"
            disabled={!isValid}
            sx={{
              "&": {
                color: "var(--primary-dark)",
                fontWeight: "bold",
                letterSpacing: ".35em",
                backgroundColor: "var(--accent)",
              },
              "&:hover": {
                backgroundColor: "var(--accent-dark)",
              },
            }}
            onClick={(event) => {
              event.preventDefault();
              sendEmail(formData, contactData.email)
                .then(() => {
                  setEmailResponseText("Email Sent!");
                })
                .catch(() => {
                  setEmailResponseText("Error Sending Email!");
                }).finally(() => {
                  clearContactForm();
                });
            }}
          >
            Send
          </Button>
          <Typography component="span" sx={{ color: emailResponseText.includes("Error") ? "red" : "var(--accent-dark)", fontWeight: "bold" }}>{emailResponseText}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
