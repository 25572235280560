import backgroundYannick from "../assets/images/portfolio/section1-background-yannick.webp"
import slackbotImage from "../assets/images/portfolio-examples/yannick/slackbot.webp"
import tickettorideImage from "../assets/images/portfolio-examples/yannick/tickettoride.webp"
import photoapplicationImage from "../assets/images/portfolio-examples/yannick/photoapplication.webp"
import pictureSection2 from "../assets/images/portfolio/section2-background-yannick.webp"
import pictureSection3 from "../assets/images/portfolio/section3-background-yannick.webp"
import pictureSection4 from "../assets/images/portfolio/section4-background-yannick.webp"

let data = {
    firstname: "Yannick",
    lastname: "Craens",
    currentJobTitle: "Full Stack Developer",
    pictureSection1: backgroundYannick,
    pictureSection2: pictureSection2,
    pictureSection3: pictureSection3,
    pictureSection4: pictureSection4,
    introduction: "My name is Yannick, and I'm a Full Stack Developer. I've always been interested in software and games. As a teenager I used to wonder how all the software I was using was made. This led me to the 'Applied Computer Science' study at Karel de Grote Highschool. Here I could learn to create the software I have always admired. In my free time I am a passionate football watcher, especially RSC Anderlecht. I believe that having played the sport myself aswell has allowed me to become a better teamplayer aswell as a great analyst. Feel free to have a look on my page and don't hesitate to contact me if you have any remaining questions!",
    skills: [
        {
          text: ".NET",
          value: 70,
        },
        {
          text: "C#",
          value: 75,
        },
        {
          text: "Java",
          value: 75,
        },
        {
          text: "HTML / CSS",
          value: 85,
        },
        {
          text: "React",
          value: 85,
        },
        {
          text: "SQL",
          value: 80,
        },
        {
          text: "Javascript",
          value: 85,
        },
        {
          text: "Dataweave",
          value: 90,
        },
        {
          text: "MuleSoft",
          value: 80,
        },
        {
          text: "Salesforce",
          value: 60,
        },
        {
          text: "SOAP",
          value: 70,
        },
        {
          text: "n8n",
          value: 55,
        },
        {
          text: "Workato",
          value: 50,
        },
    ],
    portfolioExamples: [
      {
        img: slackbotImage,
        title: "Slack Bot"
      },
      {
        img: tickettorideImage,
        title: "Ticket to Ride"
      },
      {
        img: photoapplicationImage,
        title: "PhotoApplication"
      },
    ],
    experiences: [
        {
            id: 1,
            title: "Mulesoft API Developer",
            location: "Herentals, Antwerp (BE)",
            description: "I did an internship at Dots and Arrows to dig deeper into the 'integration' process. This has allowed me to better understand the bigger picture and to create APIs in a more solution based way.",
            buttonText: "Dots and Arrows Website",
            buttonLink: "https://www.dotsandarrows.be/",
            date: "April 2022 - Present",
            icon: "work"
        },
        {
            id: 2,
            title: "Municipal Service Labourer",
            location: "Herenthout, Antwerp (BE)",
            description: "This was a vacation job I used to do when I was in middle school.",
            buttonText: "Herenthout's Website",
            buttonLink: "https://www.herenthout.be/",
            date: "July 2015 - August 2016",
            icon: "work"
        },
        {
            id: 3,
            title: "Residential Care Center Lindelo",
            location: "Lille, Antwerp (BE)",
            description: "During my time in middle school, I did some volunteer work at the local Residential Care Center.",
            buttonText: "Lindelo's Website",
            buttonLink: "http://www.lindelo.be/p/index.html",
            date: "Februari 2013 - March 2014",
            icon: "work"
        },
        {
            id: 4,
            title: "Municipal Service Labourer",
            location: "Herenthout, Antwerp (BE)",
            description: "This was a vacation job I used to do when I was in middle school.",
            buttonText: "Herenthout's Website",
            buttonLink: "https://www.herenthout.be/",
            date: "July 2013 - August 2014",
            icon: "work"
        },
        {
            id: 5,
            title: "Applied Computer Science",
            location: "Antwerp, Antwerp (BE)",
            description: "I studied at the Karel De Grote Highschool in Antwerp (Belgium), where I finished my bachelors degree in Applied Computer Science.",
            buttonText: "Degree Info",
            buttonLink: "https://www.kdg.be/toegepaste-informatica",
            date: "September 2017 - August 2022",
            icon: "school"
        },
        {
            id: 6,
            title: "Social Technical Sciences",
            location: "Vorselaar, Antwerp (BE)",
            description: "I went to middle school at the Kardinaal Van Roey Institute in Vorselaar (Antwerp, Belgium), where I finished my Social Technical Sciences studies.",
            buttonText: "Study Info",
            buttonLink: "https://kvri.be",
            date: "September 2012 - June 2017",
            icon: "school"
        },
    ],
    contactData: {
      email: "yannickcraens@thecodezone.tech"
    }
}

export default data;