import { Box, Button, Typography } from "@mui/material";

interface ScoreProps {
    score: number;
    maxScore: number;
    handleReset: () => void;
}

export default function Score({
    score,
    maxScore,
    handleReset
}: ScoreProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        fontWeight: "bold",
        fontSize: "3rem",
        gap: 2,
        height: "50vh",
        minHeight: "400px"
      }}
    >
      <Typography variant="h4">{score/maxScore < 0.5 ? "Whoops ..." : "Congratulations!"}</Typography>
      <Typography variant="h6">Your score:</Typography>
      <Typography variant="h1">
        {score} / {maxScore}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleReset}
      >
        Try Again
      </Button>
    </Box>
  );
}
