import { Experience } from "../../types/Experience";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import MovingIcon from "@mui/icons-material/Luggage";
import "./Experiences.css";
import { Box, Button, Link, Typography } from "@mui/material";

interface ExperiencesProps {
  experiences: Experience[];
}

export default function Experiences({ experiences }: ExperiencesProps) {
  const workIconStyles = { background: "#228be6", color: "white" };
  const schoolIconStyles = { background: "#e87e22", color: "white" };
  const movingIconStyles = { background: "#0ead46", color: "white" };

  return (
    <Box
      sx={{
        color: "rgb(53, 53, 53)",
        mt: "3.5em",
        mb: "15rem"
      }}
    >
      <VerticalTimeline>
        {experiences.map((experience) => {
          function getIcon() {
            switch (experience.icon) {
              case "school":
                return <SchoolIcon />;

              case "moving":
                return <MovingIcon />;

              default:
                return <WorkIcon />;
            }
          }
          function getStyles() {
            switch (experience.icon) {
              case "school":
                return schoolIconStyles;

              case "moving":
                return movingIconStyles;

              default:
                return workIconStyles;
            }
          }
          const showButton =
            experience.buttonText !== undefined &&
            experience.buttonText !== null &&
            experience.buttonText !== "" &&
            experience.buttonLink !== undefined &&
            experience.buttonLink !== null &&
            experience.buttonLink !== "";

          return (
            <VerticalTimelineElement
              key={experience.id}
              date={experience.date}
              dateClassName="vertical-timeline-element-date"
              iconStyle={getStyles()}
              icon={getIcon()}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: "1.4em",
                  fontWeight: 600,
                  mt: ".25em",
                }}
              >
                {experience.title}
              </Typography>
              <h5 className="vertical-timeline-element-subtitle">
                {experience.location}
              </h5>
              <p className="vertical-timeline-element-description">
                {experience.description}
              </p>
              {showButton && (
                <Button 
                  variant="contained"
                  className={experience.icon+'-button'}
                >
                  <Link
                    href={experience.buttonLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      textDecoration: "none",
                      color: "white"
                    }}
                  >
                    {experience.buttonText}
                  </Link>
                </Button>
              )}
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </Box>
  );
}